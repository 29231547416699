(function(wnd){
    function FormHandler(container, params) {
        try {
            if (!container) {
                throw 'Container is required!';
            }

            this.container = container;

            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    FormHandler.prototype = {
        container: undefined,
        form: undefined,
        sendedForm: false,
        type: '',
        init: function() {
            this.setElements();
            this.addDOMEvents();

        },
        setElements: function() {
            this.form = this.container.find('form');
            this.button = this.container.find('[data-purpose="send-button"]');
        },
        addDOMEvents: function() {
            var _self = this;

            this.button.click(function(e){
                e.preventDefault();
                _self.sendForm();
            });

            this.form.submit(function(e){
                e.preventDefault();
            });
        },
        setParams: function(params) {
            if (params.type) this.setType(params.type);
        },
        setType: function(type) {
            this.type = type;
        },
        sendForm: function() {
            if (!this.sendedForm) {
                var _self = this;
                this.sendedForm = true;

                $.ajax({
                    url: this.form.attr('action'),
                    dataType: 'json',
                    type:'post',
                    data: this.form.serialize(),
                    beforeSend: function() {
                        _self.form.find('.has-error').removeClass('has-error');
                        _self.form.find('[data-purpose="error-content"]').html('');
                    },
                    success: function(response) {
                        if (response.success) {
                            _self.confirmSubscribe();
                        } else {
                            $.each(response.errors, function(key, msg) {
                                _self.form.find('[name="'+key+'"]').addClass('has-error');

                                if (msg.trim() != '') {
                                    var errorContent = _self.form.find('[data-purpose="error-content"][data-field="'+key+'"]');
                                    if (errorContent && errorContent.length > 0) {
                                        errorContent.html(msg);
                                    }
                                }
                            });

                            setTimeout(function(){
                                _self.sendedForm = false;
                            }, 500);
                        }
                    }
                });
            }
        },
        confirmSubscribe: function() {
            this.form.find('.field').val('');
            this.form.find('[type="checkbox"]').prop('checked', false);
            this.container.addClass('success-sent');
        }
    };

    wnd.FormHandler = FormHandler;
})(window);
